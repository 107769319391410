<template>
  <div>
    <topbar :title="addCard"></topbar>
    <bind-card-con></bind-card-con>
  </div>
</template>
<script>
import Topbar from "../../components/Topbar.vue";
import BindCardCon from "../../components/mine/BindCardCon.vue";
export default {
  name: "BindCard",
  data() {
    return {
      cardNum: "",
      month: "",
      year: "",
      cardCode: "",
      showBtn: false,
      addCard: this.$store.state.language == 1 ? "添加信用卡" : "Add Card",
    };
  },
  methods: {
    changeBtn() {
      if (this.cardNum && this.month && this.year && this.cardCode) {
        this.showBtn = true;
      } else {
        this.showBtn = false;
      }
    },
  },
  components: {
    Topbar,
    BindCardCon,
  },
};
</script>
<style scoped>
.cardForm {
  width: 100%;
  padding: 0.16rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.cardForm input {
  width: 100%;
  height: 0.44rem;
  background: #f2f3f5;
  border-radius: 0.04rem;
  margin: 0;
  font-size: 0.12rem;
  font-family: PingFang HK;
  font-weight: 400;
  color: #b1b1b3;
  padding: 0 0.08rem;
  box-sizing: border-box;
  border: none;
  outline: none;
}
.cardForm > input {
  margin-bottom: 0.12rem;
}
.cardForm div {
  display: flex;
  justify-content: space-between;
}
.cardForm div input:first-child,
.cardForm div input:nth-child(2) {
  width: 0.64rem;
  margin-right: 0.08rem;
}
.bindBtn {
  height: 0.44rem;
  border-radius: 0.08rem;
  background-color: #ddd;
  margin: 0.48rem 0.16rem 0;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.44rem;
  color: #808080;
  text-align: center;
}
.bindBtn.active {
  color: #fff;
  background-color: #e4022b;
}
</style>